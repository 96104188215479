import { useEffect } from "react";
import Banner from "../components/Banner"
import { useLocation } from 'react-router-dom';

const Service = () => {

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/service') {
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);

  return (    
    <>
      <Banner pageTitle="Usluge" bannerClass="baner-service" />

      <section>
        <div className="container row my-5 m-auto" id="audiometrija">
          <div className="col-12 col-md-5 h-300 g-0 gx-md-4 mb-4 mb-md-0">
            <div className="border-radius-20 green-bg p-md-4 h-100 align-content-center text-center">
              <img src="images/services/audiometrija.png" alt="Audiometrija" className="icon-service" />
              <h3 className="text-white">AUDIOMETRIJA</h3>
            </div>
          </div>
          <div className="col-12 col-md-7 align-content-center g-0 gx-md-4">
            <div className="green-text border-service p-4">
              <p>Audiometrija je dijagnostički postupak koji se koristi za merenje osetljivosti sluha i identifikaciju problema sa sluhom. To je standardni test koji obavlja ORL specijalista ili audiolog. Tokom audiometrije pacijent nosi slušalice i izložen je različitim zvukovima frekvencija i glasnoća. Cilj je da pacijent čuje zvuk, kako bi se izradio audiogram, grafički prikaz rezultata. Na temelju rezultata audiometrije, moguća je identifikacija eventualnog gubitka sluha ili problema sa sluhom. Preporučuje se odgovarajuća terapija, slušna pomagala ili drugi oblici lečenja.</p>
            </div>
          </div>
        </div>

        <div className="green-bg">
          <div className="container row my-5 py-5 m-auto" id="timpanometrija">
            <div className="col-12 col-md-5 h-300 g-0 gx-md-4 mb-4 mb-md-0 d-block d-md-none">
              <div className="border-radius-20 bg-white p-4 h-100 align-content-center text-center">
                <img src="images/services/timpanometrija-green.png" alt="Audiometrija" className="icon-service" />
                <h3 className="green-text">TIMPANOMETRIJA</h3>
              </div>
            </div>
            <div className="col-12 col-md-7 align-content-center g-0 gx-md-4">
              <div className="text-white border-service-white p-4">
                <p>Timpanometrija je dijagnostički postupak koji se koristi za procenu funkcije srednjeg uva i stanja bubne opne. To je brz, neinvazivan postupak, koji se sprovodi kako bi se saznalo kako se bubna opna ponaša pri različitim varijacijama pritiska u uvu. Tokom Timpanometrije pacijentu se u uvo umetne posebna sonda koja menja pritisak u spoljašnjem uvu. Ova promena pritiska uzrokuje vibracije bubne opne na promene pritiska. Rezultati se prikazuju na timpanogramu, grafičkom prikazu odgovora srednjeg uva na pritisak. Timpanometrija može pomoći u otkrivanju problema kao što su: upala srednjeg uva, oštećenja sluha, infekcije i blokade Eustahijeve tube koja povezuje srednje uvo sa nazofarinksom.</p>
              </div>
            </div>
            <div className="col-12 col-md-5 h-300 g-0 gx-md-4 mb-4 mb-md-0 d-none d-md-block">
              <div className="border-radius-20 bg-white p-4 h-100 align-content-center text-center">
                <img src="images/services/timpanometrija-green.png" alt="Audiometrija" className="icon-service" />
                <h3 className="green-text">TIMPANOMETRIJA</h3>
              </div>
            </div>

          </div>
        </div>

        <div className="container row my-5 m-auto" id="otomikroskopija">
          <div className="col-12 col-md-5 h-300 g-0 gx-md-4 mb-4 mb-md-0">
            <div className="border-radius-20 green-bg p-4 h-100 align-content-center text-center">
              <img src="images/services/otomikroskopija.png" alt="Audiometrija" className="icon-service" />
              <h3 className="text-white">OTOMIKROSKOPIJA</h3>
            </div>
          </div>
          <div className="col-12 col-md-7 align-content-center g-0 gx-md-4">
            <div className="green-text border-service p-4">
              <p>Otomikroskopija je medicinski postupak koji se koristi za pregled spoljašnjeg uva, bubne opne i spoljašnjeg ušnog kanala. Tokom otomikroskopije lekar koristi mikroskop, koji omogućava da se područje unutar uva uveća i osvetli, kako bi se dobio jasniji i detaljniji pregled. Sprovodi se vizuelna inspekcija bubne opne, kao i pretraga eventualnih abnormalnosti, infekcija, oštećenja ili prisustvo stranih tela. Ovaj postupak je siguran i bezbolan i često se koristi u rutinskim pregledima pacijenata s problemima vezanim za uvo, kao što su upala spoljašnjeg ušnog kanala, eliminacija cerumenskih čepova ili druge abnormalnosti koje zahtevaju ovaj tretman.</p>
            </div>
          </div>
        </div>

        <div className="green-bg">
          <div className="container row my-5 py-5 m-auto" id="alergotest">
            <div className="col-12 col-md-5 h-300 g-0 gx-md-4 mb-4 mb-md-0 d-block d-md-none">
              <div className="border-radius-20 bg-white p-4 h-100 align-content-center text-center">
                <img src="images/services/alergotest-green.png" alt="Audiometrija" className="icon-service" />
                <h3 className="green-text">ALERGOTEST</h3>
              </div>
            </div>
            <div className="col-12 col-md-7 align-content-center g-0 gx-md-4">
              <div className="text-white border-service-white p-4">
                <p>Alergotest, poznat kao kožni test alergije ili kožni test senzibilizacije, je dijagnostički test koji se koristi za identifikaciju alergijskih reakcija na određene alergene. Postupak alergotesta uključuje primenu male količine različitih alergena na kožu pacijenta, obično na područje podlaktice. Nakon toga se prate reakcije kože na alergene. Ako pacijent ima alergiju na određeni alergen, na mestu primene alergena se može pojaviti crvenilo, svrab i otok. Reakcije kože se ocenjuju nakon određenog vremenskog intervala. Alergotest se često koristi za identifikaciju alergija na polen, grinje, duvanski dim, prašinu i druge stvari koje mogu izazvati alergijsku reakciju. Na temelju rezultata testa lekar može prepoznati alergene na koje je pacijent alergičan i preporučiti strategiju izbegavanja tih alergena, kao i primenu terapije antihistaminicima ili imunoterapiju.</p>
              </div>
            </div>
            <div className="col-12 col-md-5 h-300 g-0 gx-md-4 mb-4 mb-md-0 d-none d-md-block">
              <div className="border-radius-20 bg-white p-4 h-100 align-content-center text-center">
                <img src="images/services/alergotest-green.png" alt="Audiometrija" className="icon-service" />
                <h3 className="green-text">ALERGOTEST</h3>
              </div>
            </div>

          </div>
        </div>
        <div className="container row my-5 m-auto" id="videootoskopija">
          <div className="col-12 col-md-5 h-300 g-0 gx-md-4 mb-4 mb-md-0">
            <div className="border-radius-20 green-bg p-4 h-100 align-content-center text-center">
              <img src="images/services/videootoskopija.png" alt="Audiometrija" className="icon-service" />
              <h3 className="text-white">VIDEOOTOSKOPIJA</h3>
            </div>
          </div>
          <div className="col-12 col-md-7 align-content-center g-0 gx-md-4">
            <div className="green-text border-service p-4">
              <p>Videootoskopija je dijagnostički postupak koji se koristi za pregled uva, uključujući spoljašnji ušni kanal i bubnu opnu. To je napredna tehnika koja kombinuje mikroskop sa kamerom kako bi se pružio jasniji i detaljniji pregled. Tokom videootoskopije, lekar koristi videootoskop, poseban uređaj koji ima integriranu malu kameru i svetlosni izvor. Uređaj se pažljivo umetne u slušni kanal kako bi se omogućio video zapis ili slikanje bubne opne. Videoootoskopija je siguran, neinvazivan postupak i često je uključen u rutinske ORL preglede.</p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default Service