import ServiceData from "../data/ServiceData.json"
import ContactForm from "../components/ContactForm"
import { Fade } from "react-reveal"// import { Link, Element } from 'react-scroll';
import { HashLink as NavLink } from 'react-router-hash-link';
import Customers from '../components/Customers';
import { useEffect } from "react";
import { useLocation } from "react-router-dom";


const Home = () => {

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/') {
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);

  return (
    <>
      <section className="background-image-section-1 py-5">
        <div className="container fade-in-element">
          <div className="col-12 col-md-8">
            <h5 className="text-white">Ordinacija za uho, grlo i nos</h5>
            <h1 className="text-white py-3 py-md-5 font-dancing-script">Slušaj, diši, govori</h1>
            <p className="text-white pb-2">Dobrodošli u ordinaciju ORLA!</p>
            <p className="text-white">Radujemo se što vam možemo ponuditi vrhunske usluge u dijagnostici i lečenju iz oblasti uha, grla i nosa. Naš tim spreman je da vam pruži najbolju moguću negu i podršku. Tu smo da vam pomognemo da povratite zdravlje i unapredite kvalitet života.</p>
            <p className="text-white">Vaša dobrobit je naš prioritet. Trudićemo se da vam obezbedimo udobno i sigurno okruženje tokom vašeg boravka u ordinaciji. Vaša pitanja i potrebe su nam važni, stoga nas kontaktirajte kako bismo vam pomogli da ostvarite najbolje rezultate za unapređenje vašeg zdravlja. </p>
            <p className="text-white">Zahvaljujemo vam na poverenju koje nam ukazujete i radujemo se što ćemo biti deo vaše brige o zdravlju u narednim koracima.</p>
          </div>
        </div>
      </section>

      <section className="container py-5">
        <div className="row g-0">
          <div className="col-12 col-md-8 green-text pe-0 pe-md-5">
            <h1>O našoj ordinaciji</h1>
            <h5 className="py-5">Cilj rada ORL ordinacije je pružiti kvalitetnu medicinsku uslugu pacijentima u vezi problema sa uhom, grlom i nosom. Glavni ciljevi uključuju:</h5>
            <ul className="custom-list mb-5">
              <li><h5>Dijagnostika</h5></li>
              <li><h5>Lečenje</h5></li>
              <li><h5>Prevencija</h5></li>
              <li><h5>Pružanje adekvatne usluge</h5></li>
              <li><h5>Edukacija</h5></li>
              <li><h5>Praćenje novih tehnologija</h5></li>
            </ul>
            <div className="text-center text-md-start">
              <NavLink to="/about-us" className="text-decoration-none link-style-button">Pročitaj više</NavLink>
            </div>
          </div>
          <div className="col-12 col-md-4 shadow g-0 border-radius-20 mt-5 mt-md-0 text-center text-md-start">
            <div className="green-bg text-white p-4 form-home-about-us-inner-up">
              <h2>Pozovite nas</h2>
              <h4 className="py-3">
                <a className="text-decoration-none text-white" href="tel:+381 64 20 61 997">+381642061997</a>
              </h4>
              <p>ORLA specijalizovana ordinacija za lečenje uha, nosa i grla kod dece i odraslih</p>
            </div>
            <div className="row p-4 green-text form-home-about-us-inner-down">
              <h2 className="mb-5">Radno vreme</h2>
              <div className="col-12 col-md-6">
                <p>Ponedeljank - Petak</p>
                <p className="d-none d-md-block">Subota - Nedelja</p>
              </div>
              <div className="col-12 col-md-6">
                <p>8:00 - 15:00</p>
                <p className="d-block d-md-none mt-4">Subota - Nedelja</p>
                <p>neradni</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid py-5 text-center text-white background-service service-container">
        <h3 className="mb-4">Pogledajte naše</h3>
        <h1 className="white-botom-border mx-auto mb-5">Usluge</h1>
        <div className="row justify-content-center">
          {ServiceData.map((item) => (
             
            <div className="col-12 col-md-4">
              <NavLink className="nav-link" smooth to={`/service#${item.icon}`} >
              <div className="single-service-container m-2 my-3 p-5" key={item.id}>
                <img src={`/images/services/${item.icon}.png`} alt={item.name} className="icon-service" />
                <h5>{item.name}</h5>
              </div>
              </NavLink>
            </div>
            
          ))}
        </div>
      </section>

      <section className="customers-bg py-5">
        <Customers />
      </section>


      <section className="container-fluid py-5 text-white background-about-us service-container">
        <div className="container">
          <div className="text-center pb-md-5">
            <h3 className="mb-4">Doktor Specijalista</h3>
            <h1 className="white-botom-border mx-auto">O meni</h1>
          </div>
          <div className="row text-white">
            <div className="col-12 col-md-8 green-text pe-md-5 align-content-center">
              <h1 className="text-white py-5 text-center text-md-start">Moja biografija</h1>
              <p className="text-white">Doktorka Aleksandra Milosavljević, diplomirala je na Medicinskom fakultetu u Nišu. </p>
              <p className="text-white">Specijalizaciju i specijalistički ispit iz otorinolaringologije je položila sa odličnom ocenom na Klinici za otorinolaringologiju u Nišu 2015. godine.</p>
              <p className="text-white">Do 2023. godine radila kao specijalista otorinolaringologije u Zdravstvenom centru Negotin.</p>
            </div>
            <div className="col-12 col-md-4 shadow g-0 border-radius-20 bg-white">
              <img src="images/about-us/Aleksandra_Milosavljevic.webp" alt="Aleksandra Milosavljevic" className="about-us-image" />
              <div className="row p-2 p-md-4 py-5 py-md-4 green-text form-home-about-us-inner-down text-center justify-content-center">
                <h2 className="mb-4 mb-md-5">Dr Aleksandra Milosavljević</h2>
                <p>Specijalista otorinolaringologije</p>
                <div className="bb-green w-25"></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="contact" className="container py-5">
        <div className="row">
          <div className="text-center pb-md-5 green-text mb-5 mb-md-0">
            <h1 className="green-botom-border mx-auto">Kontakt</h1>
          </div>
          <div className="col-12 col-md-6 d-none d-md-block">
            <ContactForm />
          </div>
          <div className="col-12 col-md-6 align-content-center mb-5 mb-md-0">
            <Fade className="fadeInRight" delay={300}>
              <div className="row green-text text-center text-md-start">
                <div className="col-12 col-md-3 text-center text-md-end align-content-center">
                  <i className="bi bi-geo-alt-fill icon-contact"></i>
                </div>
                <div className="col-12 col-md-9 align-content-center">
                  <h5>Adresa:</h5>
                  <h6>Ulica Ljubiše Stojanovića Luisa br. 39, Negotin</h6>
                </div>
                <div className="col-12 col-md-3 text-center text-md-end align-content-center mt-4 my-md-5">
                  <i className="bi bi-telephone-fill icon-contact"></i>
                </div>
                <div className="col-12 col-md-9 my-md-5 align-content-center mb-4">
                  <h5>Telefon:</h5>
                  <h6><a className="text-decoration-none green-text" href="tel:+381642061997">+381 64 20 61 997</a></h6>
                </div>
                <div className="col-12 col-md-3 text-center text-md-end align-content-center">
                  <i className="bi bi-envelope-fill icon-contact"></i>
                </div>
                <div className="col-12 col-md-9 align-content-center">
                  <h6>Email:</h6>
                  <h6><a className="text-decoration-none green-text" href="mailto:aleksandra.milosavljevic.orl@gmail.com">aleksandra.milosavljevic.orl@gmail.com</a></h6>
                </div>
              </div>
            </Fade>
          </div>
          <div className="col-12 col-md-6 d-block d-md-none">
            <ContactForm />
          </div>
        </div>
      </section>

      <section className="contact-page-map wow fadeInUp delay-0-2s">
        <div className="map-container">
          <iframe src="https://maps.google.com/maps?q=44.2287296,22.5247913&z=15&output=embed&cid=13062821483847700055" width="100%" height="600" frameBorder="0" ></iframe>
        </div>
      </section>

    </>
  )
}
export default Home