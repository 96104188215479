import './App.css';
import { Route, Routes } from 'react-router-dom'
import Home from './pages/Home'
import AboutUs from './pages/About-us';
import Header from './components/Header';
import Service from './pages/Service';
import AboutMe from './pages/About-me';
import Price from './pages/Price';
import BackToTopButton from './components/BackToTopButton';
import PrivacyPolicy from './pages/privacy-policy';
import CookiesPolicy from './pages/cookie-policy';
import Footer from './components/Footer';

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/service" element={<Service />} />
        <Route path="/about-me" element={<AboutMe />} />
        <Route path="/price" element={<Price />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/cookie-policy" element={<CookiesPolicy />} />
      </Routes>
      <Footer/>
      <BackToTopButton />
    </>
  );
}

export default App;
