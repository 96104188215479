import { useEffect } from "react";
import Banner from "../components/Banner"
import Counter from "../components/Counter"
import PhotoGallery from "../components/PhotoGallery"

const AboutUs = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Banner pageTitle="O ordinaciji" bannerClass="baner-about-us" />

      <section className="py-5">
        <div className="container green-text">          
          <div className="row mb-5 g-0">    
            <div className="col-12 col-md-7 pe-md-5">
            <div className="row py-5 g-0">
                <img src="images/about-us/icon-about-us.webp" alt="doctor" className="col-2"/>
                <h1 className="col-10 align-content-center">rdinacija ORLA</h1>
                <div className="col-3 bb mt-3"></div>
              </div>
              <h4>Cilj rada ORL ordinacije je pružiti kvalitetnu medicinsku uslugu pacijentima u vezi problema sa uhom, grlom i nosom. </h4>
            </div>
            <div className="col-12 col-md-5 shadow g-0 border-radius-20">
              <img src="images/about-us/about-us.webp" alt="Aleksandra Milosavljevic" className="border-radius-20 w-100" />
            </div>
          </div>

          <h2 className="pb-3 pb-md-5">Glavni ciljevi uključuju:</h2>
          <div className="border-left-green px-2 px-md-5 py-3">
            <p>Dijagnostika: Identifikacija, dijagnostika i praćenje problema vezanih za uho, grlo i nos.</p>
            <p>Lečenje: Pružanje odgovarajućeg lečenja i terapije za različite bolesti i stanja koja utiču na područje uha, grla i nosa, kao i praćenje napretka tokom lečenja.</p>
            <p>Prevencija: Edukacija pacijenata o prevenciji problema sa uhom, grlom i nosom, pružanje saveta o očuvanju zdravlja i smanjenje rizika od infekcija.</p>
            <p>Pružanje adekvatne usluge: Osiguranje pažljive, ljubazne i delotvorne usluge za pacijente, kako bi se osiguralo pozitivno iskustvo tokom poseta ordinaciji.</p>
            <p>Edukacija: Stvaranje svesti o važnosti redovnih pregleda.</p>
            <p>Praćenje novih tehnologija: Sledimo najnovije medicinske inovacije i tehnologije, kako bi se omogućilo pružanje najbolje moguće usluge pacijentima.</p>
          </div>
        </div>
      </section>

      <section className="background-image-section-1 py-5 h-auto align-content-center">
        <div className="container">
          <div className="">            
            <Counter/>
          </div>
        </div>
      </section>

      <section>
        <PhotoGallery/> 
      </section>
    </>
  )
}
export default AboutUs