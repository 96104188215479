import { useEffect } from "react";
import Banner from "../components/Banner";
import PriceData from "../data/PriceData.json"

const Price = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Banner pageTitle="Cenovnik" bannerClass="baner-price" />

      <section className="container py-5 green-text">
        <div className="bb-1 mb-5">
          <h2 className="mb-3">CENOVNIK NAŠIH USLUGA</h2>
        </div>
        <div>
          <p>*U cenu nisu uključeni lekovi.</p>
          <p>Sve cene su izražene u dinarima.</p>
        </div>
        <h5 className="text-center mt-5 fw-bold">LEKARSKE USLUGE</h5>
        <div className="row mt-5 mb-4">
          <h5 className="col-6">NAZIV USLUGE</h5>
          <h5 className="col-6 text-end">CENA</h5>
        </div>
        {PriceData.map((item, key) => (
          <div key={item.id} className={`row mb-3 mt-${item.mt} bb-dotted g-0`}>
            <div className="col-10 col-md-6 green-bg text-white p-2">{item.service}</div>
            <div className="col-2 col-md-6 text-end align-content-end align-content-md-center">{item.price}</div>
          </div>
        ))}
      </section>

    </>
  )
}

export default Price;