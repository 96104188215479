import React, { useEffect } from 'react';
import Banner from '../components/Banner';

const AboutMe = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="about-me-wrapper">
      <Banner pageTitle="O meni" bannerClass="baner-about-me" />

      <section className="py-5 text-white">
        <div className="container">
          <div className="row text-white g-0">
            <div className="col-12 col-md-8 green-text align-content-center pe-md-5">
              <div className="row justify-content-center justify-content-md-start text-center text-start py-md-5 pb-5">
                <img src="images/about-us/icon-doctor.png" alt="doctor" className="col-2 w-auto p-3 p-md-0" />
                <h2 className="col-12 col-md-10 align-content-center ps-4">Dr Aleksandra Milosavljević</h2>
                <div className="col-12 col-md-3 bb mt-3"></div>
              </div>
              <p>Doktorka Aleksandra Milosavljević, diplomirala je na Medicinskom fakultetu u Nišu. </p>
              <p>Specijalizaciju i specijalistički ispit iz otorinolaringologije je položila sa odličnom ocenom na Klinici za otorinolaringologiju u Nišu 2015. godine.</p>
              <p>Do 2023. godine radila kao specijalista otorinolaringologije u Zdravstvenom centru Negotin.</p>
            </div>
            <div className="col-12 col-md-4 shadow g-0 border-radius-20 bg-white">
              <img src="images/about-us/Aleksandra_Milosavljevic.webp" alt="Aleksandra Milosavljevic" className="about-us-image border-radius-20" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AboutMe;
