import React, { useState } from "react";
import PhotoGalleryData from "../data/PhotoGalleryData.json";

const PhotoGallery = () => {
  const [visiblePhotos, setVisiblePhotos] = useState(4);
  const [isLightboxOpen, setLightboxOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(null);

  const showMorePhotos = () => {
    setVisiblePhotos((prevVisiblePhotos) => prevVisiblePhotos + 4);
  };

  const openLightbox = (index) => {
    setCurrentImageIndex(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };

  const previousImage = () => {
    setCurrentImageIndex((prevIndex) =>
      (prevIndex - 1 + PhotoGalleryData.length) % PhotoGalleryData.length
    );
  };

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % PhotoGalleryData.length);
  };

  return (
    <div className="container py-5">
      <div className="row justify-content-center gx-2 gx-md-4">
        {PhotoGalleryData.slice(0, visiblePhotos).map((item, index) => (
          <div className="col-4 col-md-3" key={index}>
            <img
              src={`/images/photo-gallery/${item.image}.webp`}
              alt={item.image}
              className="gallery-image"
              onClick={() => openLightbox(index)}
            />
          </div>
        ))}
      </div>
      {visiblePhotos < PhotoGalleryData.length && (
        <div className="button-container d-flex justify-content-center">
          <button onClick={showMorePhotos} className="green-text">Prikaži više slika</button>
        </div>
      )}
      {isLightboxOpen && (
  <div className="lightbox">
    <div className="lightbox-content">
      <img
        src={`/images/photo-gallery/${PhotoGalleryData[currentImageIndex].image}.webp`}
        alt={PhotoGalleryData[currentImageIndex].image}
        className="lightbox-image"
      />
      <button className="lightbox-close" onClick={closeLightbox}>×</button>
      <button className="lightbox-prev" onClick={previousImage}>‹</button>
      <button className="lightbox-next" onClick={nextImage}>›</button>
    </div>
  </div>
)}

    </div>
  );
};

export default PhotoGallery;










// import React, { useState } from "react";
// import PhotoGalleryData from "../data/PhotoGalleryData.json";

// const PhotoGallery = () => {

//   const [visiblePhotos, setVisiblePhotos] = useState(4);
//   const showMorePhotos = () => {
//     setVisiblePhotos((prevVisiblePhotos) => prevVisiblePhotos + 4);
//   };

//   return (
//     <div className="container py-5">
//       <div className="row justify-content-center gx-2 gx-md-4">
//         {PhotoGalleryData.slice(0, visiblePhotos).map((item) => (
//           <div className="col-4 col-md-3">
//             <img src={`/images/photo-gallery/${item.image}.webp`} alt={item.image} className="gallery-image"/>
//           </div>
//         ))}
//       </div>
//       {visiblePhotos < PhotoGalleryData.length && (
//         <div className="button-container d-flex justify-content-center">
//           <button onClick={showMorePhotos} className="green-text">Prikaži više slika</button>
//         </div>
//       )}
//     </div>
//   );
// };

// export default PhotoGallery;