import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { HashLink as NavLink } from 'react-router-hash-link';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light fixed-top header-color shadow">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img src={`/images/logo/Logo.webp`} alt="Logo" className="logo" />
          </Link>
          <div className="ml-auto d-lg-none fade-in-element">
            <button className="btn-menu-mobile custom-toggler p-0" type="button" onClick={toggleMenu}>
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>
          <div className={`collapse navbar-collapse justify-content-end mt-4 mt-md-0 ${isOpen ? 'show fade-in-element' : ''}`} id="navbarNav">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <NavLink className="nav-link green-text navigation-font" smooth to="/" onClick={closeMenu}>Početna</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link green-text navigation-font" smooth to="/about-us" onClick={closeMenu}>O ordinaciji</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link green-text navigation-font" smooth to="/service" onClick={closeMenu}>Usluge</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link green-text navigation-font" smooth to="/about-me" onClick={closeMenu}>O meni</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link green-text navigation-font" smooth to="/price" onClick={closeMenu}>Cenovnik</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link green-text navigation-font" smooth to="/#contact" onClick={closeMenu}>Kontakt</NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Header;