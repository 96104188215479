import Slider from "react-slick"
import CustomersData from "../data/CustomersData.json"

const Customers = () => {
  return (
    <div className="container">

      <Slider
        speed={2000}
        autoplaySpeed={5000}
        arrows={false}
        centerMode={false}
        focusOnSelect={true}
        fade={true}
        autoplay={true}
        slidesToShow={1}
      >        
        {CustomersData.map((item, key) => (
          <div className="" key={item.id}>
            <div className="row green-text g-0">
              <div className="col-12 align-content-center d-block d-md-none">
                <img src={`/images/customers/${item.image}.webp`} alt={item.name} className="w-100" />
              </div>
              <div className="col-12 col-md-9 align-content-center bg-white text-center p-4 p-md-5 customers-mr">
                <div className="d-flex justify-content-center pb-4">
                  <img src={`/images/customers/quotation_marks.webp`} alt={item.name}/>
                </div>
                <h3>{item.text1}</h3>
                <h2 className="my-5">{item.text2}</h2>
                <h5>{item.name}</h5>
              </div>
              <div className="col-3 align-content-center d-none d-md-block">
                <img src={`/images/customers/${item.image}.webp`} alt={item.name} className="w-100" />
              </div>
            </div>
          </div>
        ))}        
      </Slider>

    </div>
  )
}
export default Customers