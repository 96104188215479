import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <section className="green-bg">
    <div className="container text-center py-2">
      <Link to="/privacy-policy" className="me-4 text-decoration-none text-white">Politika privatnosti</Link>
      <Link to="/cookie-policy" className="ms-4 text-decoration-none text-white">Kolačići</Link>
    </div>
    </section>
  )
}
export default Footer;