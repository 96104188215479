const validateForm = (form) => {
  let isValid = true;
  form.querySelectorAll("[required]").forEach((elem) => {
    elem.classList.remove("is-invalid");
    if (!elem.value) {
      elem.classList.add("is-invalid");
      isValid = false;
    }
  });
  return isValid;
};

export default validateForm;