const Banner = ({ pageTitle, bannerClass}) => {
  return (

    <section className={`baner align-content-end py-5 ${bannerClass}`}>
      <div className="container">
        <div className="">
          <h1 className="white-botom-border-baner text-white text-center mx-auto py-5">{pageTitle ? pageTitle : "404 Not Found"}</h1>
        </div>
      </div>
    </section>
  )
}
export default Banner